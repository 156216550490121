<template>
  <v-card-title primary-title class="pt-3">
    <v-layout wrap row justify-space-between align-start justify-start fill-height class="mb-0 pa-0">
      <v-flex :class="this.$slots.button ? 'pb-0 xs12 sm4 lg6' : 'pb-0 xs12'">
        <div>
          <div v-if="this.title !== '' && this.title !== undefined" class="mb-1 title font-weight-regular">
            {{ this.title }}
          </div>
          <div v-else class="mb-1 title font-weight-regular">
            <slot name="title"></slot>
          </div>
          <div v-if="this.subtitle !== '' && this.subtitle !== undefined" class="body-2 font-weight-light">
            {{ this.subtitle }}
          </div>
          <div v-else class="body-2 font-weight-light">
            <slot name="subtitle"></slot>
          </div>
        </div>
      </v-flex>
      <v-flex v-if="this.$slots.button" xs12 sm8 lg6 class="text-xs-right pb-0">
        <slot name="button"></slot>
      </v-flex>
    </v-layout>
  </v-card-title>
</template>

<script>
export default {
  name: 'TitleCard',
  props: ['title', 'subtitle']
}
</script>
