<template>
  <v-list three-line subheader dense>
    <template v-for="(schedule, index) in getSelectedSchedule(schedules)">
      <v-list-tile :key="index" v-if="_.size(schedule) > 0">
        <v-list-tile-content small>
          <v-list-tile-title v-html="schedule.name"></v-list-tile-title>
          <v-list-tile-sub-title>Cierre L-V: {{schedule.hour_day}} / S-D: {{schedule.hour_fds}}</v-list-tile-sub-title>
          <v-list-tile-sub-title>Prioridad: {{schedule.priority}}</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn icon ripple>
            <v-icon @click="deleteAction(type, schedule.id, schedules)" color="grey lighten-1">delete</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
      <v-divider :key="`d-${index}`" v-if="index != getSelectedSchedule(schedules).length - 1"></v-divider>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'scheduleList',
  props: ['getSelectedSchedule', 'schedules', 'deleteAction', 'type'],
};
</script>

<style>

</style>
