<template>
  <v-toolbar class="elevation-0" :color="color" dark>
    <v-toolbar-title :class="subtitle === '' ? 'font-weight-regular' : 'font-weight-regular title'">
      <span v-html="title"></span>
      <template v-if="subtitle !== ''">
        <br /><span class="caption">{{ subtitle }}</span>
      </template>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <slot name="icons"></slot>
    <v-btn v-if="close" icon @click="closeAction">
      <v-icon>clear</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'TitleDialog',
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'secondary'
    },
    close: {
      type: Boolean,
      default: false
    },
    closeAction: {
      type: Function
    }
  }
}
</script>

<style></style>
