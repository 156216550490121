<template>
  <v-content>
    <v-container fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12 sm12 lg10 xl10>
          <v-layout row wrap>
            <v-flex xs12 sm6>
              <v-card class="mb-3">
                <AppTitleCard>
                  <template slot="title">
                    Tareas
                  </template>
                  <template slot="subtitle">
                    <span v-if="!allJob">{{ this.general.date | moment('DD MMMM YYYY') }}</span>
                    <span v-else>Todas las tareas pendientes</span>
                  </template>
                  <template slot="button">
                    <v-btn class="mt-0" dark color="info" small style="top: -10px !important" @click="changeDateToday">
                      Hoy
                    </v-btn>
                    <v-btn
                      class="mt-0"
                      dark
                      color="accent"
                      small
                      style="top: -10px !important"
                      @click="changeDateTomorrow"
                    >
                      Mañana
                    </v-btn>
                  </template>
                </AppTitleCard>
                <v-card-text class="pa-0">
                  <v-list class="px-0 pb-2">
                    <v-list-tile
                      avatar
                      :class="missingGeo.length > 0 ? 'error--text' : 'success--text'"
                      @click="$router.push({ name: 'DocumentGeolocalization' })"
                    >
                      <v-list-tile-action class="text-xs-center pl-2">
                        <v-icon v-if="missingGeo.length > 0">close</v-icon>
                        <v-icon v-else>check</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="missingGeo.length > 0"
                          >{{ missingGeo.length }} Pedidos sin geolocalizacion</v-list-tile-title
                        >
                        <v-list-tile-title v-else>Todos los pedidos geolocalizados</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-if="user.shop.admin"
                      avatar
                      :class="missingDocument.length > 0 ? 'error--text' : 'success--text'"
                      @click="$router.push({ name: 'DocumentOrders' })"
                    >
                      <v-list-tile-action class="text-xs-center pl-2">
                        <v-icon v-if="missingDocument.length > 0">close</v-icon>
                        <v-icon v-else>check</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title v-if="missingDocument.length > 0"
                          >{{ missingDocument.length }} Pedidos sin boleta/factura</v-list-tile-title
                        >
                        <v-list-tile-title v-else>Todos los pedidos con boletas/facturas</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-if="user.shop.admin"
                      avatar
                      :class="missingPrint.length > 0 ? 'error--text' : 'success--text'"
                      @click="$router.push({ name: 'printOrders' })"
                    >
                      <v-list-tile-action class="text-xs-center pl-2">
                        <v-icon v-if="missingPrint.length > 0">close</v-icon>
                        <v-icon v-else>check</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ missingPrint.length }} Pedidos sin imprimir</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-if="items.solicitudes.length > 0"
                      avatar
                      :class="items.solicitudes.length > 0 ? 'error--text' : 'success--text'"
                      @click="$router.push({ name: 'reqInsumos' })"
                    >
                      <v-list-tile-action class="text-xs-center pl-2">
                        <v-icon v-if="items.solicitudes.length > 0">close</v-icon>
                        <v-icon v-else>check</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ items.solicitudes.length }} Solicitudes de insumos pendientes
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-if="user.id === 6 || user.id === 10 || user.id === 12"
                      :class="checkGoogle() ? 'error--text' : 'success--text'"
                      @click="goLink('https://florista.cl/google/index.php')"
                    >
                      <v-list-tile-action class="text-xs-center pl-2">
                        <v-icon v-if="checkGoogle()">close</v-icon>
                        <v-icon v-else>check</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title> Cargar comentarios de google </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile v-if="waitings_orders.length > 0" @click="waiting_more = !waiting_more">
                      <v-list-tile-action class="text-xs-center pl-2">
                        <v-icon v-if="!waiting_more">expand_more</v-icon>
                        <v-icon v-else>expand_less</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{ waitings_orders.length }} pedidos pendientes de entrega
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <div v-if="waiting_more" class="px-4 py-1">
                      <span v-for="(order, e) in waitings_orders" :key="e">
                        <a :href="`/pedido/${order.id}/`" target="_blank">Pedido {{ order.id }}</a>
                        <span v-if="e + 1 !== waitings_orders.length"> - </span>
                      </span>
                    </div>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm6>
              <v-layout row wrap>
                <v-flex xs12>
                  <template v-if="items.orderOld.length > 0">
                    <v-card hover :class="['error', items.orderImportants.length > 0 ? 'mb-2' : 'mb-3']">
                      <v-card-title class="pl-4" primary-title>
                        <div>
                          <div class="title white--text">Pedidos con problemas de entrega</div>
                        </div>
                      </v-card-title>
                      <v-card-text class="pa-0">
                        <v-list two-line class="px-0 pb-3 error">
                          <template v-for="(order, index) in items.orderOld">
                            <v-list-tile
                              :key="index"
                              avatar
                              target="_blank"
                              :to="{ name: 'orderIndex', params: { id: order.id } }"
                            >
                              <v-list-tile-content class="white--text px-2">
                                <v-list-tile-title
                                  v-html="`Pedido ${order.id} - ${order.status.name}`"
                                ></v-list-tile-title>
                                <v-list-tile-sub-title class="text--primary">{{
                                  order.date_shipping | moment('dddd DD MMMM YYYY')
                                }}</v-list-tile-sub-title>
                                <v-list-tile-sub-title v-html="`${order.schedule}`"></v-list-tile-sub-title>
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-divider v-if="index + 1 !== items.orderOld.length" :key="`d-${index}`"></v-divider>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </template>
                  <template v-if="items.orderImportants.length > 0">
                    <v-card hover :class="['warning', items.orderImportants.length > 0 ? 'mb-3' : 'mb-3']">
                      <v-card-title class="pl-4" primary-title>
                        <div>
                          <div class="title">Pedidos destacados</div>
                        </div>
                      </v-card-title>
                      <v-card-text class="pa-0">
                        <v-list three-line class="px-0 pb-3 warning">
                          <template v-for="(order, index) in items.orderImportants">
                            <v-list-tile
                              :key="index"
                              avatar
                              target="_blank"
                              :to="{ name: 'orderIndex', params: { id: order.id } }"
                            >
                              <v-list-tile-content class="px-2">
                                <v-list-tile-title
                                  v-html="`Pedido ${order.id} - ${order.status.name}`"
                                ></v-list-tile-title>
                                <v-list-tile-sub-title
                                  style="white-space: normal"
                                  v-html="`${order.message}`"
                                ></v-list-tile-sub-title>
                              </v-list-tile-content>
                            </v-list-tile>
                            <v-divider
                              v-if="index + 1 !== items.orderImportants.length"
                              :key="`d-${index}`"
                            ></v-divider>
                          </template>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </template>
                  <v-card
                    v-if="orders_rememeber.length > 0 && (user.id === 6 || user.id === 10 || user.id === 12)"
                    class="mb-3"
                  >
                    <AppTitleCard>
                      <template slot="title">
                        Pedidos con pago pendiente
                      </template>
                    </AppTitleCard>
                    <v-card-text class="pa-0">
                      <v-list two-line>
                        <v-list-tile v-for="order in orders_rememeber" :key="order.idpedido">
                          <v-list-tile-content>
                            <v-list-tile-title>
                              <a target="_blank" :href="`/pedido/${order.idpedido}/`">Pedido {{ order.idpedido }}</a>
                            </v-list-tile-title>
                            <v-list-tile-sub-title class="text--primary">
                              {{ order.fecha_entrega | moment('DD-MM-YYYY') }} - {{ order.horario }}
                            </v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-btn dark color="info" class="px-2" flat @click="rememberOrder(order.idpedido)">
                              Recordar
                            </v-btn>
                          </v-list-tile-action>
                        </v-list-tile>
                      </v-list>
                    </v-card-text>
                  </v-card>
                  <v-card v-if="activities.length > 0" class="mb-3">
                    <AppTitleCard>
                      <template slot="title">
                        Productos desactivados por control de stock
                      </template>
                    </AppTitleCard>
                    <v-card-text class="body-1 pa-0">
                      <v-list two-line>
                        <template v-for="(item, index) in activities">
                          <v-list-tile :key="index" avatar ripple target="_blank" @click="openDialogActivity(item)">
                            <v-list-tile-avatar>
                              <img :src="item.imagen.url" />
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                              <v-list-tile-title>{{ item.product.titulo }}</v-list-tile-title>
                              <v-list-tile-sub-title class="text--primary">{{ item.message }}</v-list-tile-sub-title>
                              <v-list-tile-sub-title>
                                {{ $moment(item.date_add).format('DD-MM-YYYY HH:mm') }}
                              </v-list-tile-sub-title>
                            </v-list-tile-content>
                          </v-list-tile>
                          <v-divider v-if="index + 1 < activities.length" :key="index"></v-divider>
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-card>
                  <AppTitleCard v-if="user.shop.admin && items.polls.length > 0" class="mt-2 mb-2">
                    <template slot="title">
                      Fotos de entrega ({{ general.date | moment('DD MMMM YYYY') }})
                    </template>
                    <template slot="subtitle">
                      RECUERDE NO PUBLICAR FOTOS CON PERSONAS
                    </template>
                  </AppTitleCard>
                  <v-layout v-if="user.shop.admin && items.polls.length > 0" wrap>
                    <v-flex v-for="(poll, index) in items.polls" :key="index" xs12 sm6 lg6 xl3>
                      <v-card>
                        <v-chip
                          v-if="poll.store === 'V Región'"
                          style="position: absolute; top: 0px; z-index: 1;"
                          small
                          color="warning"
                          label
                          text-color="black"
                        >
                          {{ poll.store }}
                        </v-chip>

                        <v-img
                          :src="poll.order.photo_small"
                          aspect-ratio="1"
                          @click="openImg(poll.order.photo_large, poll.order.id)"
                        ></v-img>
                        <v-card-actions>
                          <v-btn icon color="grey" flat @click.native="publish(poll.order.id, false, index)">
                            <v-icon>visibility_off</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn small color="secondary" flat @click.native="publish(poll.order.id, true, index)">
                            Publicar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-card>
                    <AppTitleCard>
                      <template slot="title">
                        Estadistica cupones
                      </template>
                      <template slot="button">
                        <v-btn
                          class="mt-0"
                          small
                          style="top: -5px !important"
                          flat
                          :to="{ name: 'storeStatisticsID', params: { id: 'cupones' } }"
                        >
                          Mas detalle
                        </v-btn>
                      </template>
                    </AppTitleCard>
                    <v-card-text>
                      <LineChart :chart-data="datacollection" :options="optionsData"></LineChart>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- PANTALLA GRANDE-->
        <v-flex xs12 sm12 lg2 xl2>
          <v-btn color="primary" block large class="mt-0 mb-3" @click="openScheduleWeek"> Horarios </v-btn>
          <v-btn block class="mt-0 mb-3" color="error" large @click="searchStatesSchedule"> Estado comunas </v-btn>
          <v-btn
            v-if="user.id === 6 || user.id === 10 || user.id === 12"
            block
            class="mt-0 mb-3"
            color="info"
            large
            @click="updateHome"
          >
            Actualizar Home
          </v-btn>
          <DashboardOrder :items="items"></DashboardOrder>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn fixed dark fab bottom right color="accent" @click="getDashboard">
      <v-icon>refresh</v-icon>
    </v-btn>
    <v-dialog v-model="dialogPhoto" max-width="600" content-class="not-scroll">
      <v-card v-if="photoLarge" class="pb-0" style="margin-bottom: -6px; overflow: hidden">
        <img v-show="!loadingImg" :src="photoLarge" style="margin-bottom: -6px; max-width: 100%" @load="onload" />
        <div v-if="loadingImg" style="justify-content: space-around; display: flex; padding: 20px">
          <v-progress-circular :size="50" color="secondary" indeterminate></v-progress-circular>
        </div>
        <v-btn
          v-show="!loadingImg"
          :to="{ name: 'orderIndex', params: { id: orderDialog } }"
          style="bottom: 20px"
          target="_blank"
          bottom
          right
          absolute
          dark
          fab
          color="accent"
        >
          <v-icon>keyboard_arrow_right</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSchedule" max-width="600" scrollable>
      <v-card>
        <TitleDialog
          :title="`Horario del dia ${this.$options.filters.moment(this.general.date, 'DD MMMM YYYY')}`"
          :close-action="
            () => {
              dialogSchedule = false
            }
          "
          close
        ></TitleDialog>
        <v-card-text class="py-0 mb-0 px-0" style="height: 400px">
          <template v-for="(schedule, index) in schedules">
            <v-checkbox :key="index" v-model="schedulesSelected" class="my-2 px-3" :value="schedule.id" hide-details>
              <div slot="label" class="pl-2">
                <span v-html="schedule.name"></span><br />
                <span class="caption">
                  Cierre L-V: {{ schedule.hour_day }} / S-D:
                  {{ schedule.hour_fds }}
                </span>
              </div>
            </v-checkbox>
            <v-divider :key="`d-${index}`"></v-divider>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat color="back" depressed @click="dialogSchedule = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="accent" depressed @click="updateSchedule"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogScheduleWeek" max-width="1200" persistent scrollable>
      <ScheduleWeek :active="dialogScheduleWeek" :close="() => (dialogScheduleWeek = false)" />
    </v-dialog>
    <v-dialog v-model="dialog.statusStore" :fullscreen="$mq !== 'lg'" max-width="800px" scrollable>
      <v-card>
        <TitleDialog title="Estado del dia de hoy">
          <template slot="icons">
            <v-btn flat icon @click.native="dialog.statusStore = false">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
        </TitleDialog>
        <v-divider></v-divider>
        <v-card-text v-if="listStateStatus.length > 0" style="height: 500px">
          <v-layout wrap class="body-2">
            <v-flex xs6 class="pb-2 pt-2">
              <v-checkbox v-model="all" hide-details class="ma-0">
                <template slot="label"
                  ><span class="subheading font-weight-bold">Comuna</span>
                </template>
              </v-checkbox>
            </v-flex>
            <v-flex xs3 class="subheading font-weight-bold pb-2 pt-2"> Hora Cierre </v-flex>
            <v-flex xs3 class="subheading font-weight-bold pb-2 pt-2"> Estado </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <template v-for="(state, index) in listStateStatus">
            <v-layout :key="index" wrap justify-center align-center>
              <v-flex xs6>
                <v-checkbox v-model="selectedCommunesClose" :value="state" hide-details class="ma-0">
                  <template slot="label">
                    {{ state.name }}
                    <span class="caption ml-1"> ({{ state.value | toPrice }})</span>
                  </template>
                </v-checkbox>
              </v-flex>
              <v-flex xs3>
                <span v-if="!state.shipping"
                  ><v-chip label color="error" text-color="white" class="ma-0" small>
                    {{ state.timeFormat }} hrs
                  </v-chip></span
                >
                <span v-else>
                  <v-chip label color="warning" text-color="black" class="ma-0" small
                    >En {{ $moment.unix(state.time).toNow(true) }} se baja</v-chip
                  >
                </span>
              </v-flex>
              <v-flex xs3>
                <v-chip v-if="state.shipping" color="success" text-color="white" class="my-2">
                  <v-avatar>
                    <v-icon>check_circle</v-icon>
                  </v-avatar>
                  Abierto
                </v-chip>
                <v-chip v-if="!state.shipping && !state.shipping_fecha" color="error" text-color="white" class="my-2">
                  <v-icon left>pause_circle_filled</v-icon>
                  Cerrado Horario
                </v-chip>
                <v-chip v-if="!state.shipping && state.shipping_fecha" color="warning" text-color="white" class="my-2">
                  <v-icon left>pause_circle_filled</v-icon>
                  Cerrado Manual
                </v-chip>
                <v-chip v-if="state.shipping === null" color="warning" text-color="white" class="my-2">
                  <v-icon left>remove_circle</v-icon>
                  Limitado
                </v-chip>
              </v-flex>
            </v-layout>
            <v-divider v-if="listStateStatus.length !== index + 1" :key="`d-${index}`"></v-divider>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="selectedCommunesClose.length === 0"
            color="error"
            depressed
            @click.native="updateScheduleComunas('close')"
            >Cerrar</v-btn
          >
          <v-btn
            :disabled="selectedCommunesClose.length === 0"
            color="success"
            depressed
            @click.native="updateScheduleComunas('open')"
            >Abrir</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogActivities" width="500" persistent>
      <v-card>
        <TitleDialog v-if="dialogActivityItem" :title="`${dialogActivityItem.product.titulo}`" />

        <v-card-text v-if="dialogActivityItem" class="body-1">
          <v-layout wrap>
            <v-flex xs12>
              <v-subheader>¿Que desea hacer?</v-subheader>
              <v-btn-toggle v-model="dialogActivityAction">
                <v-btn flat :value="1">
                  Activar Producto
                </v-btn>
                <v-btn flat :value="2">
                  Marcar como visto
                </v-btn>
              </v-btn-toggle>
            </v-flex>
            <v-flex v-if="dialogActivityAction === 1" xs12>
              <v-subheader>¿Activar este producto?</v-subheader>
              <v-radio-group v-model="stockType" class="ml-3" row>
                <v-radio label="Stock" :value="1"></v-radio>
                <v-radio label="Normal" :value="2"></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex v-if="stockType === 1 && dialogActivityAction === 1" xs12>
              <v-text-field v-model="stockQuantity" color="secondary" :label="`Cantidad a la venta`" outline>
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn flat color="back" @click="dialogActivities = false"> volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="updateActivity()">
            <span>Actualizar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import {
  GET_ORDERS,
  GET_DASHBOARD,
  GET_STATUS_STORE,
  GET_SCHEDULE_STORE,
  TO_POST_HOME,
  MESSAGEBIRD,
  GET_PRODUCT_ACTIVITIES,
  GET_ORDER_REMEMBER,
  UPDATE_HOME,
  GET_PRODUCT
} from '../config'
import AppTitleCard from './useful/titleCard.vue'
import { firestore } from '../fireStore'
import DashboardOrder from './general/DashboardOrder.vue'
import TitleDialog from './useful/titleDialog.vue'
import ScheduleWeek from './general/scheduleWeek.vue'
import LineChart from './useful/lineChartt'

export default {
  name: 'Home',
  components: {
    LineChart,
    AppTitleCard,
    DashboardOrder,
    TitleDialog,
    ScheduleWeek
  },
  data() {
    return {
      dialogActivityItem: null,
      optionsData: {
        hover: {
          // Overrides the global setting
          mode: 'index'
        },
        line: {
          backgroundColor: 'transparent'
        },
        legend: {
          labels: {
            fontColor: 'grey',
            fontSize: 12
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: 'grey',
                fontSize: 8,
                suggestedMin: 0
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                fontColor: 'grey',
                fontSize: 12,
                stepSize: 1,
                beginAtZero: true
              }
            }
          ]
        },
        reponsive: true,
        maintainAspectRatio: false
      },
      orders_rememeber: [],
      waitings_orders: [],
      waiting_more: false,
      dialogActivities: false,
      activities: [],
      messagebird: null,
      messageBirdText: null,
      dialog: {
        statusStore: false,
        messageBird: false
      },
      listStateStatus: [],
      selectedCommunesClose: [],
      datacollection: {},
      allJob: false,
      items: {
        solicitudes: [],
        confeccion: [],
        orders: [],
        notifications: [],
        orderOld: [],
        orderImportants: [],
        polls: []
      },
      dialogPhoto: false,
      photoLarge: null,
      orderDialog: null,
      loadingImg: false,
      dialogSchedule: false,
      dialogScheduleWeek: false,
      day: [],
      days: [],
      fds: [],
      typeSelected: '',
      schedulesSelected: [],
      all: false,
      stockType: 2,
      stockQuantity: null,
      dialogActivityAction: 2
    }
  },
  computed: {
    notifications() {
      const notifications = this.$store.getters.getNotificationsInfo
      return notifications
    },
    general() {
      return this.$store.getters.getGeneral
    },
    statusFinish() {
      return this._.filter(this.items.orders, ['status', 4])
    },
    statusShipping() {
      return this._.filter(this.items.orders, ['status', 3])
    },
    statusProcess() {
      return this._.filter(this.items.orders, ['status', 2])
    },
    missingDesign() {
      return this._.filter(this.items.orders, ['design', false])
    },
    missingPrint() {
      return this._.filter(this.items.orders, ['print', false])
    },
    missingGeo() {
      return this._.filter(this.items.orders, ['location', false])
    },
    missingDocument() {
      return this._.filter(this.items.orders, ['document', false])
    },
    elaboracion() {
      return this.items.confeccion
    },
    myStyle() {
      return {
        'max-height': '250px'
      }
    },
    user() {
      return this.$store.getters.getUser
    },
    nameStatus() {
      const states = this.$store.getters.getStates
      const statusActive = this._.filter(states, ['id', this.filter.status])
      if (statusActive[0] !== undefined) {
        return statusActive[0].name
      }
      return ''
    },
    states() {
      return this.$store.getters.getStates
    },
    schedules() {
      return this.$store.getters.getSchedules
    },
    google() {
      return this.$store.getters.getGoogle
    }
  },
  watch: {
    'general.date': function date() {
      this.getDashboard()
      this.getNotifications()
    },
    notifications() {
      // this.getDashboard();
    },
    all(e) {
      if (e) {
        this.listStateStatus.forEach(element => {
          this.selectedCommunesClose.push(element)
        })
      } else {
        this.selectedCommunesClose = []
      }
    }
  },
  mounted() {
    this.getDashboard()
    this.getNotifications()
    this.getProductActivities()
    this.getOrderRemember()
  },
  methods: {
    updateActivity() {
      const { dialogActivityAction } = this
      if (dialogActivityAction === 2) {
        this.postProductActivities()
      }
      if (dialogActivityAction === 1) {
        this.changeStatusStock()
      }
    },
    async postProductActivities() {
      await this.$http.post(GET_PRODUCT_ACTIVITIES, {
        product: this.dialogActivityItem.product.idproducto
      })
      this.dialogActivities = false
      this.getProductActivities()
    },
    async changeStatusStock() {
      const { dialogActivityItem, stockQuantity, stockType } = this
      try {
        await this.$http.put(`${GET_PRODUCT}/${dialogActivityItem.product.idproducto}/update-stock`, {
          status: true,
          type: stockType,
          quantity: parseInt(stockQuantity)
        })
        this.dialogActivities = false
        this.getProductActivities()
      } catch (error) {
        this.productActive = {}
        this.stockQuantity = 1
      }
    },
    rememberOrder(id) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Enviar recordatorio?',
        description: '',
        action: () => this.rememberOrderAction(id)
      })
    },
    async updateHome() {
      this.loading = true
      await this.$http({
        method: 'GET',
        url: UPDATE_HOME
      })
      this.$store.dispatch('changeSnack', {
        active: true,
        text: 'Actualizado'
      })
    },
    async rememberOrderAction(id) {
      await this.$http({
        method: 'PUT',
        url: `${GET_ORDER_REMEMBER}/${id}`
      })
      this.$store.dispatch('setConfirm', { active: false })
      this.getOrderRemember()
    },
    async getOrderRemember() {
      const res = await this.$http({
        method: 'GET',
        url: GET_ORDER_REMEMBER
      })
      this.orders_rememeber = res.data
    },
    openActivities() {
      this.dialogActivities = true
    },
    async getProductActivities() {
      const res = await this.$http({
        method: 'GET',
        url: GET_PRODUCT_ACTIVITIES
      })
      this.activities = res.data
    },
    checkGoogle() {
      const state = this.$store.getters.getGoogle !== this.$moment().format('YYYY-MM-DD')
      return state
    },
    goLink(e) {
      this.$store.dispatch('setGoogle', this.$moment().format('YYYY-MM-DD'))
      window.open(
        e,
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    changeNight() {
      this.loading = true
      if (!this.messagebird) {
        this.dialog.messageBird = true
        return
      }
      this.updateMessageBird()
    },
    async updateMessageBird() {
      try {
        await this.$http.put(MESSAGEBIRD, {
          status: !this.messagebird,
          messageBirdText: this.messageBirdText
        })
        this.getDashboard()
        this.dialog.messageBird = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getDashboard() {
      this.loading = true
      try {
        console.log('getDashboard', GET_DASHBOARD)
        const res = await this.$http.get(GET_DASHBOARD, {
          params: {
            date: this.general.date
          }
        })
        this.items.solicitudes = res.data.forms
        this.items.confeccion = res.data.elaboracion
        this.items.orders = res.data.works
        this.items.orderOld = res.data.orderOld
        this.items.orderImportants = res.data.orderImportants
        this.items.polls = res.data.polls
        this.messagebird = res.data.messagebird
        this.messageBirdText = res.data.messagebirdText
        this.waitings_orders = res.data.waiting
        const labels = []
        const data_enviados = []
        const data_usados = []
        const cupones_enviados = res.data.cupones_enviados
        const cupones_usados = res.data.cupones_usados
        // group by data_send and data_use
        cupones_enviados.forEach(e => {
          labels.push(e.date_send)
          data_enviados.push(e.total_enviados)
          const usados = this._.filter(cupones_usados, ['date_send', e.date_send])
          if (usados.length > 0) {
            data_usados.push(usados[0].total_usados)
          } else {
            data_usados.push(0)
          }
        })

        this.datacollection = {
          labels,
          datasets: [
            {
              label: 'Cupones enviados',
              borderColor: '#4caf50',
              showLine: true,
              spanGaps: false,
              data: data_enviados,
              lineTension: 0,
              scaleStepWidth: 5
            },
            {
              label: 'Cupones usados',
              borderColor: '#ff1744',
              showLine: true,
              spanGaps: false,
              data: data_usados,
              lineTension: 0,
              scaleStepWidth: 5
            }
          ]
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async searchStatesSchedule() {
      try {
        const res = await this.$http.get(GET_STATUS_STORE, {
          params: {
            all: false
          }
        })
        this.listStateStatus = res.data
        this.selectedCommunesClose = []
        this.dialog.statusStore = true
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async openSchedule() {
      this.dialogSchedule = true
      const day = this.$moment(this.general.date).day()
      try {
        const res = await this.$http.get(GET_SCHEDULE_STORE)
        this.day = res.data.day
        this.days = res.data.days
        this.fds = res.data.fds
        const type = day === 0 || day === 6 ? 'fds' : 'day'
        this.addSchedule(type)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    openScheduleWeek() {
      this.dialogScheduleWeek = true
    },
    async updateSchedule() {
      try {
        await this.$http.post(GET_SCHEDULE_STORE, {
          type: this.typeSelected,
          schedules: this.schedulesSelected
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Horario actualizado'
        })
        this.dialogSchedule = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async updateScheduleComunas(type) {
      if (this.selectedCommunesClose.length > 0) {
        try {
          await this.$http.post(GET_STATUS_STORE, {
            type,
            communes: this.selectedCommunesClose
          })
          this.searchStatesSchedule()
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    async publish(id, publish, index) {
      const { items } = this
      try {
        await this.$http.post(TO_POST_HOME, {
          id,
          publish
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: publish ? 'Publicada' : 'Oculta'
        })
        items.polls.splice(index, 1)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    getSelectedSchedule(schedules) {
      const resp = []
      schedules.forEach(element => {
        resp.push(this._.find(this.schedules, ['id', element]))
      })
      return resp
    },
    deleteAction(type, schedule, schedules) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar horario?',
        description: '',
        action: () => this.deleteSchedule(type, schedule, schedules)
      })
    },
    addSchedule(type, schedules) {
      this.dialogNewSchedule = true
      if (type === 'day') {
        this.schedulesSelected = this.day
      } else if (type === 'fds') {
        this.schedulesSelected = this.fds
      } else if (type > 0 || type < 7) {
        this.schedulesSelected = schedules
      }
      this.typeSelected = type
    },
    openImg(img, id) {
      this.photoLarge = null
      this.orderDialog = id
      setTimeout(() => {
        this.dialogPhoto = true
        if (!this.loadingImg) {
          this.photoLarge = img
          this.loadingImg = true
        }
      }, 50)
    },
    onload() {
      this.loadingImg = false
    },
    changeDateToday() {
      const general = this.$store.getters.getGeneral
      general.date = this.$moment().format('YYYY-MM-DD')
      this.$store.dispatch('setGeneral', general)
    },
    changeDateTomorrow() {
      const general = this.$store.getters.getGeneral
      general.date = this.$moment(general.date)
        .add(1, 'days')
        .format('YYYY-MM-DD')
      this.$store.dispatch('setGeneral', general)
    },
    changeAllJob() {
      this.allJob = true
      this.$http({
        method: 'post',
        url: GET_ORDERS,
        params: {
          p: 'getDashboard',
          date: this.general.date,
          all: true
        }
      }).then(res => {
        this.items.confeccion = res.data.json.elaboracion
        this.items.orders = res.data.json.works
        this.items.orderOld = res.data.json.orderOld
        this.items.orderImportants = res.data.json.orderImportants
      })
    },
    classNotification(type, style) {
      let className = ''
      let classText = ''
      if (type == 2) {
        className = 'accent'
        classText = ''
      } else if (type == 3) {
        className = 'success'
        classText = 'white--text'
      } else if (type == 4) {
        className = 'success'
        classText = 'white--text'
      }
      return style ? className : classText
    },
    getNotifications() {
      const self = this
      firestore
        .collection('notifications')
        .doc(String(self.user.storeID))
        .collection('store')
        .orderBy('time', 'desc')
        .limit(6)
        .get()
        .then(querySnapshot => {
          const notifications = []
          querySnapshot.forEach(doc => {
            const notify = doc.data()
            notify.key = doc.id
            notifications.push(notify)
          })
          self.items.notifications = notifications
        })
    },
    handleClickOrders(value) {
      const general = this.$store.getters.getGeneral
      general.status = value
      this.$store.dispatch('setGeneral', general)
      this.$router.push({ name: 'ordersMap' })
    },
    openDialogActivity(item) {
      this.dialogActivityItem = item
      this.dialogActivities = true
    }
  }
}
</script>

<style>
.order-table tbody {
  height: 100px;
}
</style>
