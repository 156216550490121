<template>
  <v-card hover>
    <v-card-title class="pl-4" primary-title>
      <div>
        <div class="title">Pedidos</div>
      </div>
    </v-card-title>
    <v-card-text class="body px-4 pb-4 text-xs-center">
      <div @click="handleClickOrders(2)">
        <h2 class="display-3 secondary--text"> {{ statusProcess.length }} </h2>
        <h3 class="subheading"> En Proceso </h3>
      </div>
      <v-divider class="mt-3" />
      <div @click="handleClickOrders(3)">
        <h2 class="display-3 info--text"> {{ statusShipping.length }} </h2>
        <h3 class="subheading"> En reparto </h3>
      </div>
      <v-divider class="mt-3" />
      <div @click="handleClickOrders(4)">
        <h2 class="display-3  success--text"> {{ statusFinish.length }} </h2>
        <h3 class="subheading"> Entregados </h3>
      </div>
      <v-divider class="mt-3" />
      <div @click="handleClickOrders(2)">
        <h2 class="display-3"> {{ this.items.orders.length }} </h2>
        <h3 class="subheading"> Total del día </h3>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DashboardOrder',
  props: ['items'],
  computed: {
    statusFinish() {
      return this._.filter(this.items.orders, ['status', 4]);
    },
    statusShipping() {
      return this._.filter(this.items.orders, ['status', 3]);
    },
    statusProcess() {
      return this._.filter(this.items.orders, ['status', 2]);
    },
    missingDesign() {
      return this._.filter(this.items.orders, ['design', false]);
    },
    missingPrint() {
      return this._.filter(this.items.orders, ['print', false]);
    },
    missingGeo() {
      return this._.filter(this.items.orders, ['location', false]);
    },
    missingDocument() {
      return this._.filter(this.items.orders, ['document', false]);
    },
  },
};
</script>
